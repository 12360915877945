@import "src/styles/common.scss";

.header {
    &__content {
        display: flex;
    }

    &__image {
        flex: 50%;
    }

    &__text {
        flex: 50%;
    }
}

.component-container {
    height: calc(100vh - 70px);
}

.header-picture {
    max-height: 250px;
}

.header-title {
    display: flex;
    text-align: left;
    margin-bottom: 30px;
}

.column-right {
    flex: 50%;
    justify-content: flex-end;
    display: flex;
    align-items: center;
    position: relative;
}

.tv-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 25px;
}

.tv-wrapper {
    position: relative;
    filter: drop-shadow(10px 10px 0px var(--theme-drop-shadow));
}

.tv-background {
    position: absolute;
    margin-left: 10px;
    top: 4%;
    height: 40%;
}

.tv {
    position: relative;
    z-index: 1;
    cursor: pointer;
}

.tv-image-animation {
    z-index: 0;
    position: absolute;
    top: 29%;
    left: 23%;
    height: 47%;
    -webkit-animation: flicker-in-1 5s linear both;
    animation: flicker-in-1 5s linear both;
    transition: 2s ease-in;
}

.tv-text {
    color: white;
    position: absolute;
    top: 67%;
    left: 34%;
    font-size: 20px;
    -webkit-animation: flicker-1 5s linear infinite both;
    animation: flicker-1 5s linear infinite both;
}

.short-paragraph {
    width: 100%;
    text-align: center;
}

.hero-image-container {
    max-height: 300px;
}

.hero-image {
    max-height: 300px;
}

.header-text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    text-align: justify;
}

.title-1 {
    margin-bottom: 12px;
}

.title-2 {
    display: flex;
    font-size: 28px;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    color: var(--theme-title-color);
    font-weight: bold;
    margin-bottom: 60px;
    text-align: center;
    text-shadow: (1px 1px 0px var(--theme-title-shadow));
    letter-spacing: 5px;
}

.flip-card {
    background-color: transparent;
    width: 500px;
    height: 281.5px;
    perspective: 1000px;
    margin-bottom: 30px;
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;
}

.flip-card-inner {
    margin-top: 20px;
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 0 20px var(--theme-drop-shadow);
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
    transition-delay: 3s;
}

.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    flex-direction: column;
}

.flip-card-back {
    background-color: var(--theme-navbar-background);
    color: white;
    transform: rotateY(180deg);
}

.card-back-text {
    font-size: 28px;
    font-weight: bold;
    color: #fa0a79;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 75%;
}

@media screen and (max-width: $mobile) {
    .layer {
        height: auto;
    }

    .tv-text {
        font-size: 14px;
    }

    .header-container {
        height: 100%;
    }

    .flip-card {
        width: 250px;
        height: 140.5px;
    }

    .card-back-text {
        font-size: 20px;
    }
}

@media screen and (max-width: $desktop) {
    .header {
        &__content {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__image {
            flex: 100%;
            margin-bottom: 40px;
        }

        &__text {
            flex: 100%;
        }
    }
}
