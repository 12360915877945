@import "src/styles/common.scss";

.about-kites {
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    flex-direction: column;
    width: 100%;
    &_content {
        display: flex;
    }
    &_title {
        display: flex;
        width: 100%;
        justify-content: center;
        font-family: "Gotham Rounded Bold", sans-serif;
    }

    &_description {
        flex: 60%;
        font-size: 18px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 400px;
        margin: 0 50px;
        text-align: justify;
    }

    &_slider {
        display: flex;
        justify-content: center;
        flex: 40%;
        align-items: center;
        margin-left: 50px;
    }
}

@media screen and (max-width: $desktop) {
    .about-kites {
        height: 100%;
        padding-top: 50px;
        display: flex;

        &_content {
            flex: 100%;
            flex-direction: column;
        }

        &_description {
            flex: 100%;
            margin: 0;
        }

        &_slider {
            padding-top: 50px;
            margin-left: 0px;
        }
    }
}
