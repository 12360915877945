@import "src/styles/common.scss";

.slider {
    max-width: 400px;
    max-height: 400px;
}

.slider img {
    width: 100%;
    max-width: 400px;
    height: auto;
}

.awssld__wrapper {
    height: 400px;
}

.awssld__container {
    height: 400px;
}

.awssld__bar {
    background-color: transparent;
}

.awssld__content {
    background-color: transparent;
}

.awssld__next {
    right: -50px;
}
.awssld__prev {
    left: -50px;
}

.awssld__timer {
    background-color: transparent;
}

.awssld__box,
.awssld__content,
.awssld__container figure {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
}

@media only screen and (max-width: $tablet) {
    .slider {
        max-width: 230px;
        max-height: 230px;
    }
}
