@import "src/styles/common.scss";

.socials-container {
    position: fixed;
    right: 0;
    top: 50%;
    background-color: var(--theme-socials-background);
    border: 3px solid var(--theme-button-border);
    filter: drop-shadow(10px 10px 0px var(--theme-drop-shadow));
    z-index: 100;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 150px;
    transform: perspective(20vw) rotateY(-25deg);
    margin-right: 10px;
}

.social-icons {
    fill: white;
    height: 35px;
    cursor: pointer;
    filter: drop-shadow(2px 2px 0px var(--theme-drop-shadow));
    
}

.social-icons:hover {
    -webkit-animation: rotate-center 0.6s ease-in-out both;
    animation: rotate-center 0.6s ease-in-out both;
}

@-webkit-keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media screen and (max-width: $tablet) {
    .socials-container {
        display: none;
    }
}
