.light {
    --theme-page-background: "polydoge-background-light.jpg";
    --theme-color: #a80000;
    --theme-page-background: #fff;
    --theme-page-text: #272427;

    --theme-navbar-background: black;

    --theme-dropdown-background: #27262c;

    --theme-button-border: #000000;
    --theme-button-color: black;
    --theme-button-background: #fa0a79;
    
    --theme-modal-background: #6703fe;
    
    --theme-faq-title-background-color: #333333;
    --theme-faq-description-background-color: #410056;
    --theme-faq-title-hover-color: #fa0a79;
    
    --theme-drop-shadow: #313030;
    
    --theme-link-color: white;
    
    --theme-socials-background: #fa0a79;
    
    --theme-title-color: black;
    --theme-title-shadow: #fa0a79;
    transition: 2s;
}

.dark {
    --theme-page-background: "polydoge-background.jpg";
    --theme-color: #0000a8;
    --theme-page-background: #272427;
    --theme-page-text: #fff;
    
    --theme-navbar-background: black;
    
    --theme-dropdown-background: #27262c;
    
    --theme-button-border: black;
    --theme-button-color: black;
    --theme-button-shadow: black;
    --theme-button-background: #fa0a79;
    
    --theme-modal-background: #6703fe;
    
    --theme-faq-title-background-color: #333333;
    --theme-faq-description-background-color: #410056;
    --theme-faq-title-hover-color: #fa0a79;
    
    --theme-drop-shadow: #313030;
    
    --theme-link-color: white;
    
    --theme-socials-background: #fa0a79;
    
    --theme-title-color: #fa0a79;
    --theme-title-shadow: black;
    transition: 2s;
}
