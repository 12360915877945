@import "src/styles/common.scss";

.launch-date {
    display: flex;
    color: #fbce00;
}

.launch-numbers-days {
    margin-left: 15px;
    width: 50px;
}

.launch-numbers {
    margin-left: 10px;
    width: 100px;
}

.launch {
    position: fixed;
    height: 100px;
    width: 200%;
    left: -50%;
    top: 50%;
    background-color: #fa0a79;
    z-index: 999;
    transform: rotate(5deg);
}

.launch-title {
    font-family: "Gotham Rounded";
    display: flex;
    position: absolute;
    font-size: 72px;
    text-align: center;
    justify-content: center;
    width: 100%;
    white-space: pre;
    height: 100%;
    align-items: center;
}

@media screen and (max-width: $mobile), screen and (max-width: $tablet) {
    .launch-numbers-days {
        width: 20px;
    }
    .launch-numbers {
        margin-left: 5px;
        width: 30px;
    }
    .launch {
        height: 70px;
    }

    .launch-title {
        font-size: 24px;
    }
}
