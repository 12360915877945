@import "src/styles/common.scss";

.purchase-nft {
    &_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    &_sold-title {
        font-weight: bold;
        font-size: 44px;
        margin-bottom: 20px;
        text-align: center;
    }

    &_balance {
        font-weight: bold;
        font-size: 30px;
    }

    &_purchase {
        display: flex;
        width: 100%;
    }

    &_image-container {
        display: flex;
        flex: 50% 1;
        align-items: center;
        justify-content: center;
        margin: 0px 50px;
    }

    &_image {
        max-height: 400px;
        border-radius: 50%;
        border: 10px solid var(--theme-button-background);
        filter: drop-shadow(10px 10px 0px var(--theme-drop-shadow));
    }

    &_purchase-container {
        display: flex;
        flex: 50%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0px 50px;
    }
}

@media only screen and (max-width: $tablet) {
    .purchase-nft {
        &_purchase {
            flex-direction: column;
        }

        &_image-container {
            margin: 25px 0;
        }

        &_purchase-container {
            margin: 25px 0;
        }

        &_image {
            max-height: 230px;
        }
    }
}
