@import "src/styles/common.scss";

.particles {
    position: fixed;
    height: 100%;
    width: 100%;
}

@media screen and (max-width: $mobile), screen and (max-width: $tablet) {
    .launch {
        height: 70px;
    }

    .launch-title {
        font-size: 24px;
    }
}
