@import "src/styles/common.scss";

.navbar-container {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    margin-bottom: 75px;
    height: 70px;
    align-items: center;
    width: 100%;
    background-color: var(--theme-navbar-background);
    color: var(--theme-navbar-color);
    transition: 1s;
    z-index: 4;
    -webkit-animation: fade-in-bottom 3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in-bottom 3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in-bottom {
    0% {
        -webkit-transform: translateY(50px);
        transform: translateY(50px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes fade-in-bottom {
    0% {
        -webkit-transform: translateY(50px);
        transform: translateY(50px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

.navbar {
    display: flex;
    justify-content: space-between;
}

.navbar {
    margin: auto;
    //min-height: calc(100vh - 700px);
}
@media (min-width: 768px) {
    .navbar {
        width: 750px;
    }
}
@media (min-width: 992px) {
    .navbar {
        width: 970px;
    }
}
@media (min-width: 1200px) {
    .navbar {
        width: 1170px;
    }
}

.logo-container {
    display: flex;
    justify-content: flex-start;
}

.logo {
    width: 159px;
    margin: auto;
}

.casette {
    cursor: pointer;
}

.links-container {
    display: flex;
    flex: 65%;
    align-items: center;
    height: 70px;
}

.links {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.link {
    cursor: pointer;
}

.socials-container {
    display: flex;
    align-items: center;
    height: 70px;
}

a:hover {
    color: #fa0a79;
}

.active-link {
    color: #fa0a79;
}

.active-scroll-link {
    color: #fa0a79;
}

.hamburger-menu {
    display: none;
}

.hamburger-menu-dropdown {
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 0;
    top: 70px;
    min-width: 100%;
    justify-content: space-evenly;
    font-size: 30px;
    height: 50%;
    z-index: 2;
    background-color: grey;
    -webkit-animation: slit-in-vertical 0.45s ease-out both;
    animation: slit-in-vertical 0.45s ease-out both;
}

.language-menu-dropdown {
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 0;
    bottom: 70px;
    justify-content: space-evenly;
    font-size: 30px;
    z-index: 1;
    animation: navbar_slit-in-vertical__PPb26 0.45s ease-out both;
    -webkit-animation: slit-in-vertical 0.45s ease-out both;
    animation: slit-in-vertical 0.45s ease-out both;
}

.responsive-links-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
    text-align: center;
    align-items: center;
}

@-webkit-keyframes slit-in-vertical {
    0% {
        -webkit-transform: translateZ(-800px) rotateY(90deg);
        transform: translateZ(-800px) rotateY(90deg);
        opacity: 0;
    }
    54% {
        -webkit-transform: translateZ(-160px) rotateY(87deg);
        transform: translateZ(-160px) rotateY(87deg);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateZ(0) rotateY(0);
        transform: translateZ(0) rotateY(0);
    }
}
@keyframes slit-in-vertical {
    0% {
        -webkit-transform: translateZ(-800px) rotateY(90deg);
        transform: translateZ(-800px) rotateY(90deg);
        opacity: 0;
    }
    54% {
        -webkit-transform: translateZ(-160px) rotateY(87deg);
        transform: translateZ(-160px) rotateY(87deg);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateZ(0) rotateY(0);
        transform: translateZ(0) rotateY(0);
    }
}

.dark-light-theme {
    display: flex;
    align-items: center;
    width: 100px;
    justify-content: space-around;
    background: #fa0a79;
    cursor: pointer;
    height: 30px;
    border: 3px solid var(--theme-button-border);
    filter: drop-shadow(5px 5px 0px var(--theme-drop-shadow));
}

.dark-light-theme::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 50%;
    border-left: 2px solid #313030;
    transform: translate(-50%);
}

.audio-element {
    cursor: pointer;
}

.social-icons {
    fill: white;
    height: 30px;
    margin-right: 20px;
    cursor: pointer;
}

.social-icons:hover {
    -webkit-animation: rotate-center 0.6s ease-in-out both;
    animation: rotate-center 0.6s ease-in-out both;
}

.flag-wrapper {
    fill: white;
    border: 5px double;
    height: 51%;
    margin-right: 30px;
    cursor: pointer;
}

@-webkit-keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media screen and (max-width: $mobile), screen and (max-width: $tablet) {
    .navbar {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-right: 30px;
    }

    .links {
        display: none;
    }

    .dark-light-theme {
        display: none;
    }

    .dark-light-theme-mobile-wrapper {
        width: 45%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .dark-light-theme-mobile {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-around;
        background: #fa0a79;
        cursor: pointer;
        height: 30px;
        border: 3px solid var(--theme-button-border);
        filter: drop-shadow(2px 3px 0px var(--theme-drop-shadow));
    }

    .dark-light-theme-mobile::after {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 50%;
        border-left: 2px solid #313030;
        transform: translate(-50%);
    }

    .theme-wrapper {
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }

    .hamburger-menu {
        display: block;
        justify-content: flex-end;
        font-size: 30px;
        transition: 2s;
        cursor: pointer;
        color: var(--theme-link-color);
    }

    .telegram {
        display: none;
    }

    .links-container {
        flex: 30%;
    }

    .social-icons {
        display: none;
    }

    .social-mobile-container {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 50%;
    }

    .social-icons-mobile {
        height: 35px;
        cursor: pointer;
    }
}
