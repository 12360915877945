$mobile: 420px;
$tablet: 768px;
$desktop: 1220px;

.container {
    margin: auto;
    min-height: calc(100vh - 120px);
    padding-top: 70px;
}
@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}
@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}
@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}

span {
    margin-bottom: 40px;
}
.component-container {
    display: flex;
    align-items: center;
    height: calc(100vh);
    flex-direction: column;
    justify-content: center;
}

img {
    max-width: 100%;
}

.row {
    display: flex;
}

.column {
    flex: 50%;
    justify-content: center;
    display: flex;
    align-items: center;
}

a {
    text-decoration: none;
    color: var(--theme-link-color);
    display: flex;
    align-items: center;
}

h1,
h2 {
    font-family: "yanone bold", sans-serif;
    color: var(--theme-title-color);
    width: 100%;
    text-align: center;
    margin: 40px 0px 60px 0px;
    text-shadow: (3px 3px 0px var(--theme-title-shadow));
    letter-spacing: 5px;
}

h1 {
    font-size: 64px;
    letter-spacing: 1px;
    line-height: 1.05;
}

h2 {
    font-size: 54px;
}

ul {
    padding: 0;
}

li {
    list-style-type: none;
}

@media screen and (max-width: $mobile), screen and (max-width: $tablet) {
    .column {
        flex: none;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
    }

    .row {
        display: grid;
    }

    .container {
        margin-right: 30px;
        margin-left: 30px;
    }

    .component-container {
        height: 100%;
        padding: 50px 0px;
    }
}
